import * as React from "react"

export const Episodio = ({ episodio }) => {
  const hasPlayer = episodio.uri.startsWith("https://")
  const player = hasPlayer ? (
    <iframe
      id={"player-" + episodio.id}
      title={episodio.title}
      frameborder="0"
      width="100%"
      height="64"
      src={episodio.uri}
    ></iframe>
  ) : (
    <div className="episodioUri">
      <span>
        <a href={episodio.uri}>
          Escoitar programa {episodio.id}: {episodio.title}
        </a>
      </span>
    </div>
  )
  return (
    <article id={episodio.id} className="evento">
      <div className="left">
        <div>
          <strong>Programa {episodio.episode}</strong>
        </div>
        <h1>
          <span>{episodio.title}</span>
        </h1>
      </div>
      <div className="right">
        <div>{episodio.description}</div>
        {episodio.uri && player}
      </div>
    </article>
  )
}

export default Episodio;

import cartazS1 from "../images/cartaz_s1.jpg"
import cartazS2 from "../images/cartaz_s2.jpg"

export const seasons = [
  {
    id: 1,
    title: "Tempada I",
    subTitle: "UNHA VIAXE POLA HISTORIA DA MÚSICA GALEGA",
    description:
      "Unha tempada de 6 programas no que faremos unha viaxe pola historia da música galega. As orixes, os imprescindibles do folk, rock, pop ata chegar ao máis mainstream. A plataforma pretende ser un instrumento para promover a riqueza da musica e aproveitar o bo momento actual proporcionado polo actual éxito da musica galega para xerar novos públicos e novos territorios para a musica producida na Galicia. Alén do formato informativo da web, a plataforma facilita 6 podcasts, con programas producidos específicamente para o proxecto, onde serán abordados os distintos momentos e estilos da música galega.",
    romanNumber: "I",
    logo: cartazS1,
  },
  {
    id: 2,
    title: "Tempada II",
    subTitle: "UNHA VIAXE POLA HISTORIA DA MÚSICA BRASILEIRA",
    description:
      "Esta segunda edición do podcast Nas Ondas Galegas - Atravessando o Charco pretende viaxar cara o Brasil e a súa rica música. Queremos amosar as conexións entre o xigante sur americano e a Galicia.",
    romanNumber: "II",
    logo: cartazS2,
  },
]

import * as React from "react";
import {
  mainDescription,
  mainSubtitle,
  mainTitle,
} from "../data/main.data";
import logos from "../images/logos.png";

export const Footer = () => (<footer>
<div className="logos">
  <img src={logos} alt="Colaboradores" />
</div>
<ul className="social">
  <li>
    <a href="https://www.facebook.com/somosabella">
      <i className="fab fa-facebook-square" title="Facebook"></i>
    </a>
  </li>
  <li>
    <a href="https://www.instagram.com/somos.abella">
      <i className="fab fa-instagram-square" title="Instagram"></i>
    </a>
  </li>
</ul>
<h3>
  {mainTitle} | {mainSubtitle} | {mainDescription}
</h3>
</footer>);
export default Footer;